import React from "react";
import coverArt from '../../assets/img/undertheknife.jpg';
import Single from '../Single/Single'


const UnderTheKnife = (props) => {
  return (
    <Single
      title="Under the Knife"
      img={coverArt}
      url="https://open.spotify.com/playlist/0AZ7uYzrJW3FzSTmp7nzY3?si=d2073108d7d64f22"
      adType={props.ads}
    />
  )
}


export default UnderTheKnife;
