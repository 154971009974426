import React from "react";
import coverArt from '../../assets/img/pace.png';
import Single from '../Single/Single'


const Pace = (props) => {
  return (
    <Single
      title="Pace"
      img={coverArt}
      url="https://open.spotify.com/album/6ImcDXQS0ThpbP16n3lpBa?si=zs31T3uwQQ6vBGeF-GIz1A"
      adType={props.ads}
    />
  )
}


export default Pace;
