import { Route, Routes } from "react-router-dom";
import UnusualGoogle from './components/UnusualGoogle/UnusualGoogle';
import Pace from './components/Pace/Pace';
import SoWild from './components/SoWild/SoWild';
import Home from './components/Home/Home';

import './App.scss';

function App() {

  const pageVariant = window.location.pathname.replace('/', '');

  return (
    //  ads must be "gg" to report google conversion
    //  ! note: to complete set-up for google landing page, need to modify script in public/index.html
    <div className={`app background ${pageVariant}`}> 
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/unusual" element={<UnusualGoogle/>}/>
        <Route path="/pace" element={<Pace ads="fb"/>}/>
        <Route path="/pace-single" element={<Pace ads="gg"/>}/>
        <Route path="/so-wild" element={<SoWild ads="fb"/>}/>
        <Route path="/so-wild-single" element={<SoWild ads="gg"/>}/>
      </Routes>
    </div>

  );
}

export default App;
