import React, { Component } from "react";
import logo from '../../assets/img/Spotify_Logo_CMYK_White-1.png';
import coverArt from '../../assets/img/unusual.png';


class UnusualGoogle extends Component {

  reportConversion = () => {
    return window.gtag_report_conversion('https://open.spotify.com/playlist/0AZ7uYzrJW3FzSTmp7nzY3?si=143ee5af20064d59')
  }

  render() {
    return (
      <div className="component component_unusual google">
        <aside className="cover">
          <img className="cover_img" src={coverArt}  width="100%" height="100%" alt="cover art"/>
        </aside>
        <section className="info">
          <div className="heading">
            <h1 className="heading_title">Record loss</h1>
            <h2 className="heading_subtitle">Unusual</h2>
          </div>
          <div className="cta">
            <img src={logo} height="35" alt="spotify logo"/>
            <a 
              className="btn" 
              href="https://open.spotify.com/playlist/0AZ7uYzrJW3FzSTmp7nzY3?si=143ee5af20064d59" 
              // target="_blank" 
              rel="noreferrer"
              onClick={this.reportConversion}
            >
                Listen now
            </a>
          </div>
        </section>
      </div>
    )
  }
}


export default UnusualGoogle;
