import React from "react";
import coverArt from '../../assets/img/sowild.png';
import Single from '../Single/Single'


const SoWild = (props) => {
  return (
    <Single
      title="So Wild"
      img={coverArt}
      url="https://open.spotify.com/playlist/0AZ7uYzrJW3FzSTmp7nzY3?si=143ee5af20064d59"
      adType={props.ads}
    />
  )
}


export default SoWild;
