import React from 'react';
import UnderTheKnife from '../UnderTheKnife/UnderTheKnife';


const Home = () => (
  <UnderTheKnife />
);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
