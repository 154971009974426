import React from "react";
import logo from '../../assets/img/Spotify_Logo_CMYK_White-1.png';

//  for fb, just need href
//  for gg, need onclick and google report conversion event fn

const Single = (props) => {

  const reportConversion = () => {
    if (props.adType === 'gg') {
      return window.gtag_report_conversion(props.url)
    }
  }

  return (
    <div className={"component component_" + props.title}>
      
    <aside className="cover">
      <img className="cover_img" src={props.img}  width="100%" height="100%" alt="cover art"/>
    </aside>
    <section className="info">
      <div className="heading">
        <h1 className="heading_title">Record loss</h1>
        <h2 className="heading_subtitle">{props.title}</h2>
      </div>
      <div className="cta">
        <img src={logo} height="35" alt="spotify logo"/>
        <a 
          className="btn" 
          href={props.url} 
          target="_blank" 
          rel="noreferrer"
          onClick={reportConversion}
        >
          Listen now
        </a>
      </div>
    </section>
  </div>
  )
  
}


export default Single;
